<template>
    <div>
        <div
            :class="$style.subbar"
            v-if="instance"
            style="background-color: #f7f5f9"
        >
            <div class="dF aC mb-0">
                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item style="color: var(--orange)">{{
                            sidebarSelection
                        }}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>

                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <template>
                        <a-auto-complete
                            size="large"
                            v-model="tempSearch"
                            style="width: 300px"
                            :placeholder="
                                'Search for a' +
                                ' ' +
                                `${sidebarSelection
                                    .toLowerCase()
                                    .slice(0, -1)}...`
                            "
                            :dropdown-match-select-width="false"
                            :dropdown-style="{ width: '300px' }"
                            @search="handleSearch"
                            class="mr-4 searchBar"
                            @select="handleChange"
                        >
                            <a-input>
                                <a-icon slot="prefix" type="search" />
                            </a-input>
                        </a-auto-complete>
                    </template>

                    <div class="mr-4 d-none d-xl-block dash-divider" />
                    <a-button
                        @click="addNew"
                        type="primary"
                        icon="plus"
                        size="large"
                        >ADD A
                        {{
                            sidebarSelection.toUpperCase().slice(0, -1)
                        }}</a-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { getAppIcon } from "bh-mod";
import moment from "moment";

export default {
    data() {
        return {
            loadSearch: false,
            contactSearchResult: [],
            opportunitySearchResult: [],
            taskSearchResult: [],
            calendarSearchResult: [],
            tempSearch: "",
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(val) {
                console.log("RESETTING SEARCHHH");
                this.tempSearch = "";
            },
        },
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "PM"
            );
        },
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
        tags() {
            return this.$store.state.contacts.tags;
        },
        instance() {
            return this.$store.state.instance;
        },
        theApp() {
            return this.$store.state.theApp || {};
        },
    },
    methods: {
        moment,
        optionClicked(data) {
            console.log("OPTION CLICKED", data);
        },
        handleMenuClick(e) {
            console.log("ADD NEW MENU CLICKED", e);
        },
        handleSearch(input) {
            console.log("HANDLE SEARCH RAN");
        },
        handleChange(value) {
            // reference CRM Subbar
            console.log("VALUEEEE", value);
        },
        addNew() {
            if (this.sidebarSelection == "Domains") {
                console.log("ADD NEW DOMAIN CLICKED");
                this.$store.commit("ADD_DOMAIN");
            } else {
                console.log("ADD NEW DEVICE CLICKED");
                this.$store.commit("ADD_DEVICE");
            }
        },
        getAppIcon,
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.ant-form-horizontal.search-horizontal {
    label {
    }
    .ant-form-item-control-wrapper {
        flex: 1;
    }
    .ant-form-item {
        display: flex;
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 32px;
}
</style>
