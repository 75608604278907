<template>
    <div
        class="w-full h-full dF fC aS"
        style="
            display: inline-block;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        "
    >
        <LeftNavBar
            class="f1"
            :items="sidebarItems"
            @opened="selectSideBar"
            :title="selectedAppInitial"
            :selectedValue="sidebarSelection"
        >
        </LeftNavBar>
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
import vueCustomScrollbar from "vue-custom-scrollbar";
export default {
    components: { vueCustomScrollbar, LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
        };
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "PM"
            );
        },
        sidebarItems() {
            let items = [];
            if (this.publishingManagerAccess === "full") {
                items = [
                    {
                        label: "Domains",
                        value: "Domains",
                        iconUrl: require("@/assets/sideIcons/domains.svg"),
                        loading: false,
                        options: [],
                        children: [],
                    },
                    {
                        label: "Devices",
                        value: "Devices",
                        iconUrl: require("@/assets/sideIcons/devices.svg"),
                        loading: false,
                        options: [],
                        children: [],
                    },
                ];
            } else if (this.publishingManagerAccess === "domain") {
                items = [
                    {
                        label: "Domains",
                        value: "Domains",
                        iconUrl: require("@/assets/sideIcons/domains.svg"),
                        loading: false,
                        options: [],
                        children: [],
                    },
                ];
                this.$store.commit("SIDEBAR_SELECTION", "Domains");
            } else if (this.publishingManagerAccess === "device") {
                items = [
                    {
                        label: "Devices",
                        value: "Devices",
                        iconUrl: require("@/assets/sideIcons/devices.svg"),
                        loading: false,
                        options: [],
                        children: [],
                    },
                ];
                this.$store.commit("SIDEBAR_SELECTION", "Devices");
            }

            return items;
        },
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
        publishingManagerAccess() {
            return this.$store.state.appData.publishingManagerAccess;
        },
    },
    methods: {
        selectSideBar(data) {
            if (data.value == "Domains") {
                if (this.sidebarSelection != data.value) {
                    this.$store.commit("SIDEBAR_SELECTION", data.value);
                }
            } else {
                if (this.sidebarSelection != data.value) {
                    this.$store.commit("SIDEBAR_SELECTION", data.value);
                }
            }
        },
    },
};
</script>
