import Vue from "vue";
import Router from "vue-router";
import Splash from "@/layouts/Splash";
import AuthLayout from 'bh-mod/layouts/Auth'
import store from "@/store";
import { VueAppMiddleware } from "bh-mod";
import AppLayout from "bh-mod/layouts/App";

Vue.use(Router);
const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/v3",
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true },
			children: [
				// Dashboards
				{
					path: "/",
					meta: {
						title: "Publishing Manager",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app"),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: "/setup",
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [
				{
					path: "/setup",
					meta: {
						title: "Setup Publishing Manager",
						splashScreen: true,
					},
					component: () => import("./views/setup"),
				},
			],
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some((record) => record.meta.dataRequired);
	let splashScreen = to.matched.some((record) => record.meta.splashScreen);
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'domain')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (
			splashScreen &&
			(Object.keys(store.state.appData.domains).length ||
				Object.keys(store.state.appData.devices).length ||
				store.state.appData.allSettings.userApp.options.seenIntro)
		) {
			return next("/");
		}
		if (
			dataRequired &&
			!Object.keys(store.state.appData.domains).length &&
			!Object.keys(store.state.appData.devices).length &&
			!store.state.appData.getStarted &&
			!store.state.appData.allSettings.userApp.options.seenIntro
		) {
			return next("/setup");
		}
		next();
	}
	next();
});

export default router;
