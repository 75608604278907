import Vue from "vue";
import Vuex from "vuex";
import { setProp, deleteProp, arrToObj } from "bh-mod";

let tld = window.location.href.includes("bildhive.com") ? "com" : "dev";
Vue.use(Vuex);

export default {
  state: {
    tld,
    chooseColor: {
      show: false,
      data: {},
    },
    allSettings: {},
    devices: [],
    domains: [],
    projects: [],
    touchscreens: [],
    getStarted: false,
    publishingManagerAccess: "full",
  },
  mutations: {
    NEW_DOMAIN(state, data) {
      state.domains.push(data);
    },
    DELETE_DOMAIN(state, data) {
      let index = state.domains.findIndex((x) => x.id == data.id);
      state.domains.splice(index, 1);
    },
    DELETE_DEVICE(state, data) {
      let index = state.devices.findIndex((x) => x.id == data.id);
      state.devices.splice(index, 1);
    },
    NEW_DEVICE(state, data) {
      state.devices.push(data);
    },
    UPDATE_DOMAINS(state, data) {
      let newDomains = JSON.parse(JSON.stringify(state.domains));
      let index = newDomains.findIndex((x) => x.id == data.id);
      newDomains[index] = data;
      state.domains = newDomains;
    },
    UPDATE_DEVICE(state, data) {
      let newDevices = JSON.parse(JSON.stringify(state.devices));
      let index = newDevices.findIndex((x) => x.id == data.id);
      newDevices[index] = data;
      state.devices = newDevices;
    },
    SET_SETTINGS(state, data) {
      let temp = data;
      if (data.userApp == null) {
        temp.userApp = {
          options: {
            seenIntro: false,
          },
        };
        state.allSettings = temp;
      } else {
        state.allSettings = data;
      }
    },
    SET_APPDATA(state, data) {
      if (!data) data = {};
      state.devices = data.devices;
      state.domains = data.domain;
      state.projects = data.projects;
      state.touchscreens = data.touchscreens;
      state.publishingManagerAccess = data.publishingManagerAccess || "full";
    },
    GET_STARTED: (state) => {
      state.getStarted = true;
    },
  },
  actions: {
    SET_APPDATA: ({ commit }, data) => commit("SET_APPDATA", data),
  },
  getters: {
  },
};
