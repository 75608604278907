import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import appData from "./appData";
import settings from "./settings";
import library from "./library";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    settings,
    appData,
    library,
  },
  state: {
    instances: [],
    instance: null,
    hives: [],
    cats: [],
    theApp: {},
    sidebarSelection: "Domains",
    domainModal: {
      visible: false,
    },
    deviceModal: {
      visible: false,
    },
    connectDomain: {
      domain: {},
      visible: false,
    },
    renameDevice: {
      device: {},
      visible: false,
    },
    restartDevice: {
      device: {},
      visible: false,
    },
    instructionsVisible: false,
	fileStructure:[]
  },
  mutations: {
	SET_FILE_STRUCTURE(state, data) {
		state.fileStructure = data;
	},
    OPEN_INSTRUCTIONS(state) {
      state.instructionsVisible = true;
    },
    CLOSE_INSTRUCTIONS(state) {
      state.instructionsVisible = false;
    },
    ADD_DEVICE(state) {
      state.deviceModal = {
        visible: true,
      };
    },
    CLOSE_ADD_DEVICE(state) {
      state.deviceModal = {
        visible: false,
      };
    },
    ADD_DOMAIN(state) {
      state.domainModal = {
        visible: true,
      };
    },
    CLOSE_ADD_DOMAIN(state) {
      state.domainModal = {
        visible: false,
      };
    },
    RENAME_DEVICE(state, data) {
      state.renameDevice = {
        device: data,
        visible: true,
      };
    },
    CLOSE_RENAME_DEVICE(state) {
      state.renameDevice = {
        device: {},
        visible: false,
      };
    },
    RESTART_DEVICE(state, data) {
      state.restartDevice = {
        device: data,
        visible: true,
      };
    },
    CLOSE_RESTART_DEVICE(state) {
      state.restartDevice = {
        device: {},
        visible: false,
      };
    },
    CONNECT_DOMAIN(state, data) {
      state.connectDomain = {
        domain: data,
        visible: true,
      };
    },
    CLOSE_CONNECT_DOMAIN(state) {
      state.connectDomain = {
        domain: {},
        visible: false,
      };
    },
    SET_APP(state, data) {
      state.theApp = data;
    },
    SET_INSTANCE: (state, data) => {
      state.instance = data;
      state.cats = data.activeApps;
    },
    SIDEBAR_SELECTION(state, data) {
      state.sidebarSelection = data;
    },
  },
  getters: {},
  actions: {},
});
